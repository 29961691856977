
import { Component, Prop } from "vue-property-decorator";
import { getComponent, getConfigEnv, jsonParse, sleep } from "@/utils/helpers";
import { mixins } from "vue-class-component";
// import AWS from 'aws-sdk';
// import {
//   POST_SHAPEFILE_LAMBDA_REGION,
//   IDENTITY_POOL_REGION,
//   IDENTITY_POOL_ID,
// } from '@/lib/constants';
import DataLoading from "../../lib/mixins/dataLoading";
import TasqJob from "../../interfaces/tasqs/TasqJob";
// import setpointV2Module from '@/store/modules/setpointV2Module';
// import tasqFeedbackModule from '@/store/modules/tasqFeedbackModule';
// import metaDataModule from '@/store/modules/metaDataModule';
// import tasqsListModule from '@/store/modules/tasqsListModule';
// import workflowModule from '@/store/modules/workflowModule';
import Multiselect from "vue-multiselect";
import axiosClient from "@/lib/rest/axiosClient";
import GptMixin from "./GptMixin";
import gptModule from "@/store/modules/gptModule";
import GenericMixin from "@/lib/mixins/GenericMixin";

@Component({
  components: {
    SpinnerLoader: () => getComponent("loaders/SpinnerLoader"),
    TasqSetpointFeedback: () => getComponent("tasqs/TasqSetpointFeedback"),
    Multiselect,
  },
})
export default class GptPlungerSummary extends mixins(DataLoading, GptMixin,GenericMixin) {
  @Prop({ required: true }) tasq!: TasqJob;

  isLoadingPlot = false;

  showTpLpPopup = false;

  showTpPopup = false;

  refreshingList = false;

  selectedInput = "";

  acceptedValue = "";

  message = "";

  isSavingData = false;

  showAllEvents = false;

  isLoadingData = false;

  updateList() {
    this.refreshingList = true;
    this.showAllEvents = !this.showAllEvents;

    this.$nextTick(() => {
      this.refreshingList = false;
    });
  }

  isMedianPerPostive(val){
    if(this.isNumberConvertable(val)){
      if (val < 0) {
      return val <= -30 ? "text-accentError200 bg-accentError200" : "text-yellow-500 bg-yellow-500";
    } else if (val > 0) {
      return "text-accentSuccess200 bg-accentSuccess200";
    }
    }else{
      return "text-accentSuccess200 bg-accentSuccess200";
    }
  }

  updateInputKey(e) {
    this.isLoadingData = true;
    console.log(e);
    this.$nextTick(() => {
      this.isLoadingData = false;
    });
  }

  getImpactClass(value) {
    if (value < 0) {
      return value <= -30 ? "text-accentError200": "text-yellow-500" ;
    } else if(value > 0) {
      return "text-accentSuccess200";
    }else{
      return "text-green-500";
    }
  }

  getImpactState(value) {
    if (value < 0) {
      return value <= -30 ? "MAJOR OUTAGE" : "MODERATE DECREASE";
    } else if (value > 0) {
      return "SIGNIFICANT INCREASE";
    } else {
      return "STABLE";
    }
  }

  get isValidForm() {
    if (this.acceptedValue) {
      return true;
    }
    return false;
  }

  changepoints: any = [];

  inputKeys: any = [];

  async created() {
    await this.fetchPlungerEvents();
  }

  isNumberConvertable(value) {
    // Check if the value is already a number.
    if (typeof value === "number") {
      return true;
    }

    // Try to convert the value to a number.
    const convertedValue = Number(value);

    // Check if the converted value is a valid number.
    if (isNaN(convertedValue)) {
      return false;
    }

    // The value can be converted to a number.
    return true;
  }

  async fetchPlungerEvents() {
    this.isLoadingData = true;
    this.selectedInput = "";
    try {
      // const filterPayload = {
      //   operator: operatorDetails["operatorName"],
      //   node_name: this.tasq.wellName,
      // };
      const changepoints = gptModule.plungerSummary;
      await sleep(2000);
      if (changepoints) {
        this.changepoints = changepoints;
        console.log(this.changepoints);
        this.inputKeys = Object.keys(this.changepoints);

        this.selectedInput = this.inputKeys[0];
      }
    } catch (error) {
      console.log(error);
    }



    this.isLoadingData = false;
  }

  get selectedKeyData() {
    const data = JSON.parse(JSON.stringify(this.changepoints[this.selectedInput]));
    if (data.events) {
      const events = data.events.map((e) => {
        return {
          ...e,
          formattedDate: this.formatDate(e.date),
          dateTime: new Date(e.date).getTime(),
        };
      });
      data["events"] = events.sort((a, b) => b.dateTime - a.dateTime);
    }
    if (data.events && !this.showAllEvents) {
      data.events = data.events.slice(0, 5);
    }
    return data;
  }

  formatDate(date) {
    const options: any = { month: "short", day: "numeric", year: "numeric" };

    let dateObj = new Date(date);

    let startFormatted = dateObj.toLocaleDateString("en-US", options);

    return `${startFormatted}`;
  }

  roundToInteger(number) {
    if (number) {
      return Math.round(number);
    }
    return number;
  }

  isMedianPerPostive2(val){
    if(this.isNumberConvertable(val)){
      if (val < 0) {
      return val <= -30 ? "text-accentError200" : "text-yellow-500";
    } else if (val > 0) {
      return "text-accentSuccess200 ";
    }
    }else{
      return "text-accentSuccess200 ";
    }
  }
}
