import { render, staticRenderFns } from "./GptPlungerFeedback.vue?vue&type=template&id=12253992&scoped=true&"
import script from "./GptPlungerFeedback.vue?vue&type=script&lang=ts&"
export * from "./GptPlungerFeedback.vue?vue&type=script&lang=ts&"
import style0 from "./GptPlungerFeedback.vue?vue&type=style&index=0&id=12253992&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12253992",
  null
  
)

export default component.exports